import React, { useState, useEffect, useRef } from "react";
import top from "../../assets/whatsapp.png";
import "../Teammembers/teammembers.css";
import Footer from "../footer/footer";
import Subhashsir from "../../assets/Team members/Sejal Ma'am.webp";
import Sejalmaam from "../../assets/Team members/Subhash Sir.webp";
import Seemamaam from "../../assets/Team members/Seema Ma'am.webp"
import tejas from "../../assets/Team members/Tejas.webp";
import harsh from "../../assets/Team members/Harsh.webp";
import shreya from "../../assets/Team members/Shreya.webp";
import kirti from "../../assets/Team members/Kirti.webp";
import krishna from "../../assets/Team members/Krishna.webp";
import puja from "../../assets/Team members/Puja.webp";
import saurabh from "../../assets/Team members/Saurabh.webp";
import saurav from "../../assets/Team members/Saurav.webp";
import rohan from "../../assets/Team members/Rohan.webp";
import mrunali from "../../assets/Team members/Mrunali.webp";
import prachi from "../../assets/Team members/Prachi.webp";
import sushant from "../../assets/Team members/Sushant.webp";
import pradnya from "../../assets/Team members/Pradnya.webp";
import shubham from "../../assets/Team members/Shubham.webp";
import pratik from "../../assets/Team members/Pratik.webp";
import nagesh from "../../assets/Team members/Nagesh.webp";
import mukul from "../../assets/Team members/Mukul.webp";
import shriram from "../../assets/Team members/Shree.webp";
import kushi from "../../assets/Team members/Khushi.webp";
import mayur from "../../assets/Team members/Mayur.webp";
import surbhi from "../../assets/Team members/Surbhi.webp";
import apurva from "../../assets/Team members/Apurva.webp";
import ronit from "../../assets/Team members/Ronit.webp";
import pritesh from "../../assets/Team members/Pritesh.webp";
import vidisha from "../../assets/Team members/Vidhisha.webp";
import sachin from "../../assets/Team members/Sachin.webp";


import Lottie from "react-lottie";
import teamshero from "../../component/json/AI Brain Mapping.json";
import plane from "../../assets/Home/plane.json";


function Teammembers() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: teamshero,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [isVisible, setIsVisible] = useState(false);
  const containerRef8 = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef8.current) {
      observer.observe(containerRef8.current);
    }

    return () => {
      if (containerRef8.current) {
        observer.unobserve(containerRef8.current);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

  return (
    <div className="teammembers">
      <div className="back-2-top">
        <a
          href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20"
          target="_blank"
        >
          <img src={top} alt="Whatsapp" />
        </a>
      </div>
      <div className="container-fluid team-sec">
        <div className="row">
          <div className="col-md-7 members-hero">
            <div className="members-hero-text">
              <p>Our Team</p>
              <h1>
                At our core, we are powered by a group of passionate and
                enthusiastic designers, strategists, and analysts.
              </h1>
            </div>
          </div>
          <div className="col-md-5 members-hero-img d-flex justify-content-center align-items-center">
            <Lottie
              options={defaultOptions}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>

      <div members-section1>
        <div className="members-head-2">
          <div className="rocket-up-team" ref={containerRef8}>
            {isVisible && (
              <Lottie
                options={{
                  autoplay: true,
                  loop: false,
                  animationData: plane,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                style={{ height: "20%", width: "30%", margin: 0 }}
              />
            )}
          </div>
          <h3>
            Meet The Team That Turns <span>'Work'</span> Into <span>'Wow'</span>
            <br />
            One Brainstorm At A Time!
          </h3>
          {/* <h1>Our Core Team</h1> */}
          <h2>
            At SAAA talent is nurtured, business is conducted with empathy, and
            services are delivered with unwavering devotion.
          </h2>
          <div className="core">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={Seemamaam} alt="Global Director" />
                    </div>
                    <div className="team-text">
                      <h1>Seema Patil</h1>
                      <h2>Global Director</h2>
                      {/* <p>- A man must have a code.</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={Sejalmaam} alt="Diector" />
                    </div>
                    <div className="team-text">
                      <h1>Subhash Sarda</h1>
                      <h2>Director</h2>
                      {/* <p>- I'll make them a design they can't develop.</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={Subhashsir} alt="Director" />
                    </div>
                    <div className="team-text">
                      <h1>Sejal Dukhande</h1>
                      <h2>Director | CEO</h2>
                      {/* <p>
                        - Dil bada hona chahiye!
                      </p> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="core">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={saurav} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Saurav Singh</h1>
                      <h2>Co-Head, Digital Marketing</h2>
                      <p>- A man must have a code.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={rohan} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Rohan Matle</h1>
                      <h2>Co-Head, Web Development</h2>
                      <p>
                        - This is your world. Shape it or someone else will.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={saurabh} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Saurabh Rajguru</h1>
                      <h2>Co-Head, Designing</h2>
                      <p>- I'll make them a design they can't develop.</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={ronit} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Ronit</h1>
                      <h2>Creative Head</h2>
                    
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="core">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={tejas} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Tejas Naik Satam</h1>
                      <h2>Senior Manager, Digital Marketing</h2>
                      <p>- You miss 100% of the shots you don't take.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={puja} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Puja Naik</h1>
                      <h2>Manager, Web Development</h2>
                      <p>- There are no free lunches in life.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={harsh} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Harsh Pathak</h1>
                      <h2>Manager, Designing</h2>
                      <p>- <span>Design mein Logic nhi sirf Magic hota hai.</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="core">
            <div className="container-fluid">
              <div className="row">

                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={krishna} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Krishna Nagpure</h1>
                      <h2>Senior Developer, Web Development</h2>
                      <p>- Tension maat lo, hojayenga boss 👍🏼</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={shreya} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Shreya Joshi</h1>
                      <h2>Manager, Digital Marketing</h2>
                      <p>- Live and let me live.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={nagesh} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Nagesh Bansode</h1>
                      <h2>Analyst, Digital Marketing</h2>
                      <p>
                        - Embrace innovation, transcend boundaries, and redefine
                        possibilities in the digital era.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="core">
            <div className="container-fluid">
              <div className="row">
                {/* <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={shrutik} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Shrutik Kupekar</h1>
                      <h2>Developer, Web Development</h2>
                      <p>
                        - Will we end the website or will the website end us.
                      </p>
                    </div>
                  </div>
                </div> */}
                 <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={mukul} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                    <h1>Mukul Raut</h1>
                      <h2>Senior Developer, Web Development</h2>
                      <p>- Yeh dukh kahe khatam nahi hota be.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={kirti} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Kirti Bhilare</h1>
                      <h2>Accountants Manager, Finance</h2>
                      <p>
                        - Life is like accounting, everything must be balanced.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={sushant} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Sushant Kumbhar</h1>
                      <h2>Compliance Analyst, Legal Compliance</h2>
                      <p>- Blessed with the Best.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="core">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={shubham} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Shubham Choudhari</h1>
                      <h2>Senior Graphic Designer, Designing</h2>
                      <p>-The truth will set you free.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={pradnya} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Pradnya Dombe</h1>
                      <h2>Developer, Web Development</h2>
                      <p>- Trust your struggles and skills.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={pratik} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Pratik Deshmukh</h1>
                      <h2>Senior Graphic Designer, Designing</h2>
                      <p>- Kaam karo, Naam karo!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
          <div className="core">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={prachi} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Prachi Gupta</h1>
                      <h2>Compliance Analyst, Legal Compliance</h2>
                      <p>- Let the beauty of what you love be what you do.</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={shriram} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Shriram Punekar</h1>
                      <h2>Senior Executive, Legal Compliance</h2>
                      <p>- Tu mere bulane pe nahi aaya lekin mujhe gussa nahi aaya, dekh may khud chala aaya.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={mrunali} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Mrunali Morye</h1>
                      <h2>Executive, Legal Compliance</h2>
                      <p>- Only Judge can judge me.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="core">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={kushi} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                    <h1>Khushi Rathi</h1>
                      <h2>Digital Analyst, Digital Marketing</h2>
                      <p>- Oh oh jaane jaanaaa,
                      All I think about is khanaaa!!.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={mayur} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      
                      <h1>Mayur Jaigade</h1>
                      <h2>Motion Graphic Designer, Designing</h2>
                      <p>-Life is too short to argue, Just say... Abhi thik karke deta hu.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={surbhi} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Surbhi Jadhav</h1>
                      <h2>Content Writer, Digital Marketing</h2>
                      <p>- I'm a writer. Anything you say or do may be used in a story.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="core">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={pritesh} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                    <h1>Pritesh Rote</h1>
                      <h2>Senior Graphic Designer, Designing</h2>
                      <p>- तो बोला तू कैसे कला किसकी कैसी?</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={apurva} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      
                      <h1>Apoorva Somwanshi</h1>
                      <h2>Business Development Manager</h2>
                      <p>- She thought she could so she did.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={vidisha} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                      <h1>Vidisha Shetty</h1>
                      <h2>Digital Analyst, Digital Marketing</h2>
                      <p>- Chase your dreams, but remember to take naps.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="core-alpha">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="team-card">
                    <div className="team-img">
                      <img src={sachin} alt="Team Mate" />
                    </div>
                    <div className="team-text">
                    <h1>Sachin Palekar</h1>
                      <h2>Digital Analyst, Digital Marketing</h2>
                      <p>- Not trying hurts harder than failure, toh aage badho mere dost!</p>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Teammembers;
