import React, { useState, useEffect, useRef } from "react";
import "../casestudies/casestudies.css";
// import casevid from "../../assets/contact/form-vid.png";
import soot from "../../assets/blogs/incorporate.webp";
import funding from "../../assets/blogs/funding.webp";

import kmc from "../../assets/Case Studies/kmccase.webp";
import ziva from "../../assets/Case Studies/zivacase.webp";
import toon from "../../assets/Case Studies/tooncase.webp";
import fabriety from "../../assets/Case Studies/Fab.webp";
import namalogy from "../../assets/Case Studies/namalogycase.webp";
import lupin from "../../assets/Case Studies/lupincase.webp";
import ekvity from "../../assets/Case Studies/ekvitycase.webp";
import holisticcase from "../../assets/Case Studies/holisticcase.svg";
import Footer from "../../component/footer/footer";
import Lottie from "react-lottie";
import casehero from "../json/Case Study.json";
import { HashLink } from "react-router-hash-link";
import download from "../../assets/download.svg";
import Smmcasestudy from "../../assets/pdf/smmpdf.pdf";
import Ekvitycasestudy from "../../assets/pdf/ekvitycasestudy.pdf";
import Fabrietycasestudy from "../../assets/pdf/fabrietycasestudy.pdf";
import Holisticcasestudy from "../../assets/pdf/holisticcasestudy.pdf";
import Lupincasestudy from "../../assets/pdf/lupincasestudy.pdf";
import Namalogycasestudy from "../../assets/pdf/namalogycasestudy.pdf";
import top from "../../assets/whatsapp.png";


const Blogs = () => {
    useEffect(() => {
    window.scrollTo({ top: 0, behaviour: "smooth" });
  }, []);

 
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: casehero,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
 
    return(
        <>
    <div className="casestudies">
       <div className="back-2-top">
        <a href="https://api.whatsapp.com/send?phone=7977895134&text=Hello%20" target="_blank">
    <img src={top} alt="Whatsapp"/>
  </a>
        </div>
        <div className="container-fluid case-sec">
            <div className="row">
            <div className="col-md-7 members-hero">
                <div className="members-hero-text">
                <p>Blogs</p>
                <h1>
                                    Dive into insightful articles, expert tips, and the latest trends 
                        in the digital world to help your business thrive.  
                </h1>
                </div>
            </div>
            <div className="col-md-5 members-hero-img d-flex justify-content-center align-items-center">
                <Lottie
                options={defaultOptions}
                style={{
                    height: "100%",
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",}}
                />
            </div>
            </div>
        </div>
     
      <div className="case">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img blogs-img">
                  <img src={soot} alt="SootSparsh"/>
                </div>
                <div className="case-text">
                  <h1>The complete guide on how to incorporate a company in India.</h1>
                  <h2>
                  From understanding how to pick the perfect name to seal the deal with the incorporation certificate, we've broken down each step to make it easy.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href="/blogs/how-to-incorporate-company-in-india" smooth>
                        <span>Read More</span>   <svg id="Layer_1" className="blogs-arrow" width="35px" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m23.939 12-3.454-1.995v1.653l-20.424.342 20.424.342v1.653z"/>
               </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="case-card">
                <div className="case-img blogs-img">
                  <img src={funding} alt="SootSparsh"/>
                </div>
                <div className="case-text">
                  <h1>Top strategies to raise funds for your business.</h1>
                  <h2>
                  Whether you're launching a startup or looking to expand an existing business, it is very important to learn and understand how to raise funds for your business.
                  </h2>
                  <div className="case-btnhome">
                    <div className="case-actual-btn">
                      <a href="/blogs/strategies-to-raise-funds" smooth>
                        <span>Read More</span>   <svg id="Layer_1" className="blogs-arrow" width="35px" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m23.939 12-3.454-1.995v1.653l-20.424.342 20.424.342v1.653z"/>
               </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
        </>
    );
};
export default Blogs;