import React, { useState } from 'react';
import Lottie from "react-lottie";
import casehero from "../json/Case Study.json";
import "../blogs/blogs.css";
import soot from "../../assets/blogs/incorporate.webp";
const IncorporateCompany = () => {
const defaultOptions = {
loop: true,
autoplay: true,
animationData: casehero,
rendererSettings: {
preserveAspectRatio: "xMidYMid slice",
},
};
// Setting 'tab1' as the default open question
const [activeTab, setActiveTab] = useState('tab1');
const toggleTab = (tab) => {
setActiveTab(activeTab === tab ? null : tab); // Toggle the active tab
};
return(
<>
<div class="blog-details">
   <div className="container-fluid case-sec">
      <div className="row">
         <div className="col-md-12">
            <a href="/blogs" class="back-button">
               <svg id="Layer_1" className="back-arrow" width="35px" enable-background="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m23.939 12-3.454-1.995v1.653l-20.424.342 20.424.342v1.653z"/>
               </svg>
               Back to Blogs
            </a>
         </div>
         <div className="col-md-12">
            <div className="blogs-hero-text">
               <p>The complete guide on how to incorporate a company in India.
               </p>
               {/* 
               <h1>
                  Dive into insightful articles, expert tips, and the latest trends 
                  in the digital world to help your business thrive.  
               </h1>
               */}
            </div>
         </div>
      </div>
      <div className="row justify-content-center mobile-reverse">
         <div className="col-lg-8">
            <div className="blogdetail-img">
               <p>Below is a comprehensive guide on everything you need to know about incorporating a company. You can use the links in the sidebar to navigate directly to the section you're most interested in and skip the rest.</p>
               <img className="w-100 py-4" src={soot} />
            </div>
            <div className="blogdetails-content">
               <section id="section0">
                  <p>Starting a business in India is the gateway to limitless opportunities but incorporating a company can seem complicated due to the paperwork and legal steps involved. From choosing the right business type to getting the approvals and registrations, there’s much to look into.
                  </p>
                  <p>Well, this detailed blog has got you covered. From understanding how to pick the perfect name to seal the deal with the incorporation certificate, we've broken down each step to make it easy.</p>
                  <p>Incorporating a company is like giving your business its own legal personality, separate from its owners. This process is your gateway to legitimacy and growth, governed by the Companies Act, 2013 and overseen by the Ministry of Corporate Affairs (MCA). It's all about following the right steps to ensure your business meets the necessary regulations and guidelines.
                  </p>
               </section>
               <section id="section1">
                  <h2>How to incorporate a company?</h2>
                  <p>Incorporating companies in India offers numerous benefits and opportunities for entrepreneurs, companies, and investors.</p>
                  <p>Let’s understand how:
                  </p>
                  <ol>
                     <li><b>Limited Liability:</b> Incorporating a business in India provides the big advantage of limited liability. This means that shareholders are only responsible for what they've invested in the company. As a result, their personal belongings are usually protected from the company’s debts and liabilities.</li>
                     <li><b>Credibility and Legal Status:</b> A company registered in India is recognized as a separate legal entity, independent of its owners. This legal distinction boosts up the company's credibility, assuring customers, suppliers, financial institutions, and other stakeholders about the company's legitimacy and reliability.
                     </li>
                     <li><b>Opportunities for Financing:</b> Registered companies in India have access to a wide range of financing options, such as issuing shares, securing loans, and attracting venture capital investments. These avenues of funding can significantly aid in scaling operations, investing in new technologies, and driving growth.
                     </li>
                     <li><b>Tax Benefits:</b> Companies in India may benefit from corporate tax rates that can be more advantageous than individual tax rates. The government also provides special tax benefits and exemptions for businesses in specific industries or areas to encourage economic growth and attract investment.
                     </li>
                     <li><b>Simplified Business Operations:</b> India has made substantial improvements in creating a simplified business environment. Reforms such as the implementation of online filing systems, streamlined regulatory processes, and other initiatives have made it easy for companies to start and operate smoothly.</li>
                     <li><b>Opportunities for International Growth:</b> Starting a company in India gives access to both local and global markets. With its large number of consumers, growing middle class, and varied economy, India offers great opportunities for businesses looking to expand and build a presence in the Asia-Pacific region and beyond.
                     </li>
                  </ol>
               </section>
               <section id="section2">
                  <h2>Let’s understand the types of companies in India:</h2>
                  <p>Before you begin the process of registering a company in India, it’s important to understand the different types of companies you can register. Each type has its own legal structure, benefits, and compliance requirements. 
                  </p>
                  <ul>
                     <li><b>Private Limited Company (Pvt Ltd):</b> Ideal for small to medium-sized businesses, it offers limited liability to its shareholders and allows them to raise funds from venture capitalists or private equity.
                     </li>
                     <li><b>Public Limited Company (Ltd):</b> Suitable for larger businesses that intend to raise capital from the public through shares, with stricter regulatory requirements.
                     </li>
                     <li><b>One Person Company (OPC):</b> Designed for solo entrepreneurs who want to avail the benefits of a private limited company with only one director and shareholder.</li>
                     <li><b>Limited Liability Partnership (LLP):</b> Combines the benefits of a partnership with limited liability protection for its partners, often chosen by professional services firms.</li>
                     <li>
                        <b>Section 8 Company (Non-Profit Organisations):</b> Established for charitable purposes, these companies enjoy certain tax exemptions.
                        <p>Understanding these options will help you choose the structure that best fits your business goals and compliance capabilities.</p>
                     </li>
                  </ul>
               </section>
               <section id="section3">
                  <h2>Why is it important to select the right business type?</h2>
                  <p>Selecting the right business structure is an important decision as it affects your company’s operations, tax obligations, liability, and ability to raise capital</p>
                  <p>You must consider the following criteria before making the right decision:</p>
                  <ul>
                     <li><b>Liability Protection:</b> Assess how much personal liability protection you need. Structures like Private Limited Companies and LLPs offer limited liability, which means your assets are protected from business debts.</li>
                     <li><b>Funding Requirements:</b> If you plan to seek external funding, a Private Limited Company or Public Limited Company might be more appropriate as they are favored by investors and banks.</li>
                     <li><b>Taxation:</b> Different business structures are taxed differently. For instance, LLPs have a pass-through taxation system, whereas companies are subject to corporate tax.
                     </li>
                     <li><b>Ownership and Management:</b> Think about how you want to structure ownership and management. A Private Limited Company allows for separate ownership and management, while an OPC gives full control to a single person.</li>
                     <li><b>Compliance Burden:</b> Some business structures, like Public Limited Companies, come with higher compliance and regulatory requirements.</li>
                  </ul>
               </section>
               <section id="section4">
                  <h2>The Step-by-Step process for incorporating your business.
                  </h2>
                  <ul>
                     <li>
                        <b>Step 1: Get a Digital Signature Certificate (DSC)</b>
                        <p>Since the entire company registration process is online, you'll need a Digital Signature Certificate (DSC) to submit forms on the MCA portal. DSCs are mandatory for all proposed directors and those who will sign the Memorandum of Association (MoA) and Articles of Association (AoA).</p>
                        <p>You can get a DSC from government-approved certifying authorities. It’s possible to get it online within two days, and directors need to get a Class 3 DSC.</p>
                     </li>
                     <li>
                        <b>Step 2: Apply for Director Identification Number (DIN)</b>
                        <p>A Director Identification Number (DIN) is a unique ID for directors. Anyone who wishes to be a director in a company needs a DIN. When you are registering a company using the SPICe+ form, you can get a DIN for up to three directors. If you want to start a company with more than three directors, and more than three of them don't have a DIN, you'll need to begin by incorporating the company with three directors first. After the company is set up, you can then add more directors.</p>
                     </li>
                     <li>
                        <b>Step 3: Register on the MCA Portal</b>
                        <p>To register your company in India, you’ll need to fill out and submit the SPICe+ form on the MCA portal. The company director must first sign up on the MCA portal to gain access to services like filing forms and viewing documents. Part of the registration involves registering your company’s name. You’ll need to propose two names in the SPICe+ form (Part-A). The name shouldn’t be similar to any existing company, LLP, or trademark, and it shouldn’t contain any prohibited words.</p>
                        <p>If a company's proposed name is rejected because it is too similar to an existing company's name, an LLP, or a trademark, or it doesn't follow the Companies (Incorporation Rules) 2014, the applicant must submit another SPICe+ form and pay the required fee. Once the name is approved, it will be reserved for 20 days, during which the company must complete the incorporation process by submitting Part-B of the SPICe+ form.</p>
                     </li>
                     <li>
                        <b>Step 4: Receive the Certificate of Incorporation</b>
                        <p>After submitting your registration form and documents, the Registrar of Companies will review your application. If everything is in order, you’ll receive a Certificate of Incorporation, which includes your company’s CIN (Corporate Identification Number) PAN and TAN (provided by the Income Tax Department). The certificate will be sent to you via email, along with the PAN and TAN details.
                        </p>
                        <p>This should help make the company registration process clearer and easier to understand.</p>
                     </li>
                     <li>
                        <b>Documents Required for Company Registration</b>
                        <p>When registering an LLP, One Person Company, Private Limited, or Public Limited Company, you'll need to submit the following documents:</p>
                        <p><b>For the Directors and Shareholders (or Partners in the case of LLP):</b></p>
                     </li>
                     <li>
                        <b>A.</b> Identification Proof: Each director and shareholder (or partner) needs to provide one of the following as ID proof:
                        <ul className="inner-list">
                           <li>- PAN card </li>
                           <li>- Aadhaar card </li>
                           <li>- Driving license </li>
                           <li>- Passport </li>
                        </ul>
                     </li>
                     <li>
                        <b>B.</b> Address Proof: Each director and shareholder (or partner) must provide one of the following as proof of address:
                        <ul className="inner-list">
                           <li>- Recent telephone bill (not older than 2 months) </li>
                           <li>- Recent electricity bill (not older than 2 months) </li>
                           <li>- Bank account statement showing the address </li>
                           <li>- DIN (or DPIN for LLP) and DSC: All directors (or partners) must have a Director Identification Number (DIN) and Digital Signature Certificate (DSC). </li>
                        </ul>
                     </li>
                     <li>
                        <b>C.</b> For the Company or LLP:
                        <ul className="inner-list">
                           <li>- Registered Office Proof: You need to provide proof of the company’s registered office address. This can include:
                           </li>
                           <li>- Tenancy/rental agreement between the landlord and the company/LLP </li>
                           <li>- A letter or No Objection Certificate (NOC) from the landlord permitting to use the premises as the company’s/LLP’s registered office </li>
                           <li>- Sale deed of the office premises in the name of the company/LLP </li>
                           <li>-  Memorandum of Association (MoA): This document outlines the objectives for which the company is being incorporated and the liabilities of its members.</li>
                           <li>- Articles of Association (AoA): This document sets out the rules and regulations that govern the company’s operations.
                           </li>
                        </ul>
                     </li>
                  </ul>
               </section>
               <section id="section5">
                  <h2>How much does it cost to incorporate a Company?
                  </h2>
                  <ul>
                     <li>- One Person Company Registration in India: ₹7,000 – ₹10,000*
                     </li>
                     <li>- LLP Registration in India: ₹7,000 – ₹15,000*</li>
                     <li>- Private Limited Company Registration in India: ₹6,000 – ₹30,000*</li>
                     <li>- Public Limited Company Registration in India: ₹6,000 – ₹30,000*</li>
                  </ul>
                  <p>*Please note that these costs are approximate and may vary based on factors like authorized capital, professional fees, stamp duty, and the number of directors and members.</p>
               </section>
               <section className='faq-section'>
                  <h2>FAQs
                  </h2>
                  <div className="faq-container">
                     {/* Question 1 */}
                     <div className="faq-tab" onClick={() =>
                        toggleTab('tab1')}>
                        <div className="faq-header">
                           <h4 className="faq-question">What is MOA and AOA?</h4>
                           <span className="faq-arrow">{activeTab === 'tab1' ? '▲' : '▼'}</span>
                        </div>
                        {activeTab === 'tab1' && (
                        <div className="faq-answer">
                           <p>- MOA (Memorandum of Association): A document that outlines the fundamental objectives and scope of a company’s operations.</p>
                           <p>- AOA (Articles of Association): A document that defines the internal rules and regulations governing the management and operations of a company.</p>
                        </div>
                        )}
                     </div>
                     {/* Question 2 */}
                     <div className="faq-tab" onClick={() =>
                        toggleTab('tab2')}>
                        <div className="faq-header">
                           <h4 className="faq-question">Can foreigners set up their own company in India?
                           </h4>
                           <span className="faq-arrow">{activeTab === 'tab2' ? '▲' : '▼'}</span>
                        </div>
                        {activeTab === 'tab2' && (
                        <div className="faq-answer">
                           <p>Yes, non-Indian individuals or companies can establish a business in India, subject to compliance with specific regulations.</p>
                           <p>Need help with company incorporation in India or any other business services? We are always here to help you!</p>
                        </div>
                        )}
                     </div>
                  </div>
               </section>
               <p><b>Does all of this information sound overwhelming/complex? We will make it simple for you! <a className='footer-blog-link' href="/contactform">Contact us</a> today!!</b></p>
            </div>
         </div>
         <div className="col-lg-3">
            <div className="blog-sidebar">
               <h2>On this page</h2>
               <ul>
                  <li>
                     <a href="#section1">How to incorporate a company?</a>
                  </li>
                  <li>
                     <a href="#section2">Types of companies in India</a>
                  </li>
                  <li>
                     <a href="#section3">Why is it important to select the right business type?</a>
                  </li>
                  <li>
                     <a href="#section4">The Step-by-Step process for incorporating your business.</a>
                  </li>
                  <li>
                     <a href="#section5"> How much does it cost to incorporate a Company?</a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
};
export default IncorporateCompany;